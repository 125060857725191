import React from 'react'
import { graphql } from 'gatsby'
import {
  AdStarBlock,
  Category,
  GeneralSettings
  // SponsoredCategories
} from '../../types'
import { IMenu } from '../../graphql/entities/Menu'
import TopBanner from '../../components/Banners/TopBanner/TopBanner'
import Content from '../../components/Content/Content'
import Footer from '../../components/Footer/Footer'
import Header from '../../components/Header/Header'
import AwardsHeader from '../../components/MuddyAwards/AwardsHeader'
import AwardsMenu from '../../components/MuddyAwards/AwardsMenu'
import Breadcrumb from '../../components/organisms/breadcrumb/Breadcrumb'
import PageMeta from '../../components/PageMeta/PageMeta'
import Leaderboard from '../../components/Banners/Leaderboard/Leaderboard'
// import MuddyCategoryBlocks from '../../components/MuddyAwards/MuddyCategoryBlocks'
// import BlocksWrappers from '../../components/Blocks/BlocksWrappers'

export const query = graphql`
  query {
    wpgraphql {
      generalSettings {
        title
        siteId
        egSettingTwitter
        url
      }
      adStarCodes {
        id
        adslot
      }
      awardsMenu: menu(id: "Awards", idType: NAME) {
        menuItems {
          nodes {
            id
            path
            label
          }
        }
      }
      category(id: "muddy-awards", idType: SLUG) {
        sponsoredBy
        sponsoredLogo
      }
    }
  }
`

interface AwardsFAQProps {
  data: {
    wpgraphql: {
      generalSettings: GeneralSettings
      adStarCodes: AdStarBlock[]
      awardsMenu: {
        menuItems: {
          nodes: IMenu[]
        }
      }
      category: Category
    }
  }
}

const AwardsFAQs = ({
  data: {
    wpgraphql: {
      generalSettings,
      adStarCodes,
      awardsMenu: {
        menuItems: { nodes: subMenu }
      },
      category
    }
  }
}: AwardsFAQProps) => {
  return (
    <>
      <PageMeta
        pageData={{
          ...generalSettings,
          title: 'Muddy Stilettos Awards 2024',
          description:
            'The Muddy Stilettos Awards are the most coveted awards for independent lifestyle businesses across 28 counties, and totally FREE to enter!',
          image:
            'https://muddystilettos.co.uk/images/awards/Awards_Badge_2024.gif'
        }}
      />
      <Header />
      <TopBanner ads={adStarCodes} pageType={`awards`} />
      <Leaderboard adSlot={'TopLB'} />
      {category && (
        <AwardsHeader category={category} generalSettings={generalSettings} />
      )}
      {subMenu && (
        <AwardsMenu subMenu={subMenu} generalSettings={generalSettings} />
      )}
      <div>
        <Content>
          <Breadcrumb
            postType={`awards`}
            postTitle={'/ FAQs'}
            postURI={'/awards/faqs'}
          />
          <h1 dangerouslySetInnerHTML={{ __html: 'FAQs' }} />
          <p className="has-text-color" style={{ color: '#d41a50' }}>
            How do the Muddy Awards work?
          </p>

          <p>The Muddy Awards operate in three stages:</p>

          <p>
            <strong>Stage 1: Nominations</strong> (20 Feb – 14 March 2024).
            Nominations open in 21 lifestyle categories. You may put forward one
            business in your county, per category per email address.
            <br />
            <strong>Stage 2: Regional Finals </strong>(3 April – 18 April 2025).
            A maximum of 5 regional finalists per category will be announced on
            3 April 2024 and public voting lasts until 1pm, 18 April 2024 with
            Regional Winners being announced on 2 May 2024
            <br />
            <strong>Stage 3: National Finals</strong> (3 July- 11 July 2024).
            Regional Winners will automatically go through to the National
            Finals and the Muddy Stilettos Editors will judge who is the Best of
            the Best announcing the National Winners on 11 July 2024
          </p>

          <p className="has-text-color" style={{ color: '#d41a50' }}>
            Can I nominate a business?
          </p>
          <p>
            Yes, you can nominate any business in your county providing it
            satisfies the category criteria. This might be a business that you
            are a customer of, or your own business.
          </p>

          <p className="has-text-color" style={{ color: '#d41a50' }}>
            What is the cost?
          </p>
          <p>The Muddy Stilettos Awards remain FREE from start to finish.</p>

          <p className="has-text-color" style={{ color: '#d41a50' }}>
            Can I share my personal email Nomination or Regional Finals voting
            link when I nominate a business so that others can use it too?
          </p>
          <p>
            No, it’s a link that is unique to you, and cannot be shared. To
            nominate or vote in the Regional Finals, you must sign in with your
            email address and a unique link will be sent. One link and one vote
            protects the Awards from possible voter fraud.
          </p>

          <p className="has-text-color" style={{ color: '#d41a50' }}>
            Are nominations carried over from the first stage to the second
            voting stage of the Regional Finals?
          </p>
          <p>
            No, we wipe the slate clean. The Regional Finals require you to
            choose between five specific named businesses in each category and
            therefore voting starts again from scratch.
          </p>

          <p className="has-text-color" style={{ color: '#d41a50' }}>
            When will the Regional winners be announced?
          </p>
          <p>
            Regional Winners will be announced on 2 May 2024. No correspondence
            will be entered into. Winners will be privately invited to a
            Regional Awards party.
          </p>

          <p className="has-text-color" style={{ color: '#d41a50' }}>
            How do I enter the National Muddy Awards ?
          </p>
          <ul>
            <li>
              Every Regional Winner in every category will be put forward for
              consideration in the Muddy Stilettos National Awards 2024.
            </li>
            <li>
              Muddy Stilettos Editors will be asked to mark their candidates
              accurately 1-10 using the Muddy Stilettos Awards judging criteria
              and we will create a National final of 5 per category.
            </li>
            <li>
              Every Editor will be invited to put forward their case in a round
              table as to why they believe their regional winners should win the
              contested categories.{' '}
            </li>
            <li>
              The final say on each category winner will go to Hero Brown,
              Founder and Editor in Chief at Muddy Stilettos.
            </li>
            <li>National Finalist announcement: 3 July 2024</li>
            <li>National Winners announcement: 11 July 2024</li>
          </ul>

          <p className="has-text-color" style={{ color: '#d41a50' }}>
            What do I win?
          </p>
          <p>Each Regional and National winner will receive:</p>
          <ul>
            <li>A Muddy Stilettos Awards gold window rosette</li>
            <li>A Muddy Stilettos Awards gold certificate</li>
            <li>A Muddy Stilettos Awards website rosette</li>
            <li>
              Awards-related publicity with direct links to businesses on Muddy
              Stilettos
            </li>
            <li>
              Networking opportunity with other winning local businesses at the
              Awards’ drinks
            </li>
          </ul>

          <p className="has-text-color" style={{ color: '#d41a50' }}>
            Can I be nominated in more than one category?
          </p>
          <p>
            Yes. You can be nominated in more than one category (eg café and
            casual dining) However, for more than one location (i.e if you have
            a store/venue in two or more towns) we suggest picking just one to
            focus on as votes across separate locations and categories
            won&apos;t be combined at the final count.
          </p>

          <p className="has-text-color" style={{ color: '#d41a50' }}>
            Can we enter a local business that’s part of a national franchise?{' '}
          </p>
          <p>Yes.</p>

          <p className="has-text-color" style={{ color: '#d41a50' }}>
            Can we enter the same business for more than one county (eg two
            cafes operating in different counties)?
          </p>
          <p>Yes.</p>

          <p className="has-text-color" style={{ color: '#d41a50' }}>
            Can I nominate my own business?
          </p>
          <p>
            Yes, you or a member of staff can nominate your business and can
            download the nomination assets.
          </p>

          <p className="has-text-color" style={{ color: '#d41a50' }}>
            I haven’t had an email from Muddy Stilettos yet to say I’ve been
            nominated, but a client has told me that they have put me forward?
            What should I do?
          </p>
          <p>
            Send an email to{' '}
            <a href="mailto:awards@muddystilettos.co.uk">
              awards@muddystilettos.co.uk
            </a>{' '}
            and we will confirm that you have been nominated. Please give your
            business name, county and category so we can easily find you.
          </p>
        </Content>
      </div>
      <Leaderboard adSlot={'BottomLB'} />
      <Footer />
    </>
  )
}

export default AwardsFAQs
